.homebutton:hover{
    background-color: #ffffff;
    color: #000000;
    cursor: url(../../public/blackheartfilled.png), auto

}
.aboutme {
    margin: 1rem;
    text-decoration: none;
    color: #595959
}

.aboutme:hover {
    font-style:oblique;
    color:rgb(243, 85, 177);
    text-decoration: wavy underline;
    animation: jump 1s ;
    /* animation: 3s infinite alternate slidein; */
    transform-origin: center bottom;
    cursor: url('../../public/blackheartfilled.png'), auto;	
}

.portfolio {
    margin: 1rem;
    text-decoration: none;
    color: #595959
}

.portfolio:hover {
    font-style:oblique;
    color:rgb(0, 170, 200);
    text-decoration: wavy underline;
    animation: jump 1s;
    /* animation: 3s infinite alternate slidein; */
    transform-origin: center bottom;
    cursor: url('../../public/blackheartfilled.png'), auto;	
}


@keyframes jump {
    from,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    90% {
        transform: translate3d(0, -4px, 0);
    }
}