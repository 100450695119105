#paragraphs {
    font-family: monospace;
    font-size:medium;
}
#normal {
    margin-left: 7px;
    margin-right: 7px;
    color: black;
    display: inline;
}

#coral {
    color:rgb(255, 77, 0);
    display: inline;
}
#blue {
    color:rgb(0, 89, 255);
    display: inline;
}
#green {
    color:rgb(0, 215, 11);
    display: inline;
}

#purple {
    color:rgb(241, 0, 237);
    display: inline;
    text-decoration: none;
}

#purple:hover {
    color:rgb(255, 139, 212);
    cursor: url('../../public/blackheartfilled.png'), auto;	
}

#gold {
    color:rgb(255, 196, 0);
    display: inline;
}

.links ul {
    list-style: none;
    padding: 0px;
}

.links li {
    display: inline-block;
    margin-right: 30px; /* add spacing between items */

}

.links a{
    text-decoration:none; 
    color: rgb(255, 101, 234); 
}

.links a:hover{
    color: rgb(0, 221, 255);
    cursor: url('../../public/blackheartfilled.png'), auto;	
}


@keyframes jump {
    from,
    20%,
    53%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -15px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    90% {
        transform: translate3d(0, -4px, 0);
    }
}

.jump {
    animation: jump 1s;
    transform-origin: center bottom;
}

@keyframes flip {
    from {
        transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
        animation-timing-function: ease-out;
    }

    40% {
        transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
        animation-timing-function: ease-out;
    }

    50% {
        transform: perspective(700px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
        animation-timing-function: ease-in;
    }

    80% {
        transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
        animation-timing-function: ease-in;
    }

    to {
        transform: perspective(400px);
        animation-timing-function: ease-in;
    }
}

.flip:hover {
    backface-visibility: visible;
    animation: flip 1.3s;
}